import {createContext, useState} from "react";

export const LangContext = createContext({});

export function LangProvider({children}) {
  const [lang,setLangInfo] = useState({});
  return (
    <LangContext.Provider value={{lang,setLangInfo}}>
      {children}
    </LangContext.Provider>
  );
}
