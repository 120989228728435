import React, { useEffect, useRef, useState } from 'react'
import styles from './Contact.module.css';
import contact from '../../Assets/contact.png';
import fb from '../../Assets/ig.png';
import ig from '../../Assets/fb.png';
import wechat from '../../Assets/wechat.png';
import kakao from '../../Assets/KakaoTalk_logo.svg';
import success from '../../Assets/success.png';
import axios from 'axios';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser'

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [about, setAbout] = useState([]);
    const [popup, setPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const form = useRef();

    useEffect(() => {
        if(isLoading) { 
            axios.get('https://wwa.zyberapi.site/about')
            .then((e) => {setAbout(e.data.data); setIsLoading(false)})
            .catch((e) => {alert(e); setIsLoading(false)});
        }
    }, [isLoading])  

    const sendFeedBack = (e) => {
        e.preventDefault();

        axios.post('https://wwa.zyberapi.site/feedback', {
            name: name, 
            email: email,
            content: message,
        })
        .then(() => {
            emailjs.sendForm('service_yms8t6c', 
            'template_9g8s8q2', form.current,
            'fuI9gL2j5AQofNE3X')
            .then(() => {
                setPopup(true);
            })
            .catch((e) => alert(e.text));
        })
        .catch((e) => alert(e))
    }
  return (
    <>
        {isLoading ? <div className='loader_container'><span className='loader'></span></div> : popup ? 
            <div className={styles.popup}>
                    <div className={styles.popup_content}>
                        <div>
                            <h2>Send feed back</h2>
                            <p>Thank you so much</p>
                        </div>
                        <img src={success} alt="" />
                        <Link className={styles.popup_button} to={'/'}>Home</Link>
                    </div>
            </div> : 
            <div className={styles.unit}>
                
              <div className={styles.container}>
                <div className={styles.items}>
                    <h2>About</h2>
                    {about.map((e) => (
                        <div className={styles.about} dangerouslySetInnerHTML={{__html: e.content}}/>
                    ))}    
                </div>
                <div className={styles.items}>
                    {/* <img className={styles.contact_icon} src={contact} alt="" /> */}
                    {about.map((e) => (
                        // <div className={styles.about} dangerouslySetInnerHTML={{__html: e.content}}/>
                        <img className={styles.contact_icon} src={'https://wwa.zyberapi.site/' + e.cover} alt="" />
                    ))}   
                    <div className={styles.icons}>
                        <a href="https://facebook.com"><img src={fb} alt="" /></a>
                        <a href="https://instagram.com"><img src={ig} alt="" /></a>
                        <a href="https://instagram.com"><img src={kakao} alt="" /></a>
                        <a href="https://instagram.com"><img src={wechat} alt="" /></a>
                    </div>
                    <form ref={form} onSubmit={sendFeedBack} className={styles.contact}>
                        <input name='user_name' onChange={(e) => setName(e.target.value)} type="text" placeholder="Name" className={styles.input} />
                        <input name='user_email' onChange={(e) => setEmail(e.target.value)} type="text" placeholder="Email" className={styles.input} />
                        <textarea name='message' onChange={(e) => setMessage(e.target.value)} className={styles.textArea} placeholder="Message" cols="30" rows="10"></textarea>
                        <button value='Send' className={styles.button}>Send</button>
                    </form>
                </div> 
              </div>
            </div>
    }
    </>
  )
}

export default Contact
