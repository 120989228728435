import React, { useEffect, useState } from 'react'
import styles from './PlanDetial.module.css';
import icon from '../../../public/global_icon.svg'
import profile from '../../../public/edit-info.png'
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import {
  MdOutlineArrowDropDown,
} from "react-icons/md";
import './planDetail.css';
// import { LangContext } from '../../../LangContext';

const PlanDetail = () => {
  const { id } = useParams();
  const [post, setPost] = useState([]);
  const [photo, setPhoto] = useState([]);
  const [comment, setComment] = useState([]);
  const [plan, setPlan] = useState([]);
  const [name, setName] = useState('');
  const [writeComment, setWriteComment] = useState('');
  const [country, setCountry] = useState('');
  const [files, setFiles] = useState('');
  const [file, setFile] = useState('');
  const [className, setClassName] = useState(null);
  // const {lang} = useContext(LangContext);


    useEffect(() => {
        axios.get(`https://wwa.zyberapi.site/categories/${id}/post`)
        .then((response) => setPost(response.data.data))
        .catch((error) => alert(error));

        axios.get(`https://wwa.zyberapi.site/categories/${id}/photo`)
        .then((response) => setPhoto(response.data.data))
        .catch((error) => alert(error));

        axios.get(`https://wwa.zyberapi.site/categories/${id}/comment`)
        .then((response) => setComment(response.data.data))
        .catch((error) => alert(error));

        axios.get(`https://wwa.zyberapi.site/categories/${id}/plan`)
        .then((response) => setPlan(response.data.data))
        .catch((error) => alert(error));
      }, [id]);

      function handleChange(e) {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
        setFiles(e.target.files);
      }

      async function createNewPost(ev) {
        ev.preventDefault();
      
        const data = new FormData();
        data.append('name', name);
        if (files[0]) {
          data.append('file', files[0]);
        }
        data.append('category', id);
        data.append('content', writeComment);
        data.append('country', country);
      
        try {
          await fetch(files.length === 0 ? 'https://wwa.zyberapi.site/commentno' :'https://wwa.zyberapi.site/comment', {
            method: 'POST',
            body: data,
            credentials: 'include',
          })
          .then((e) => {
            // <Navigate to={'/'} />;
            window.location.reload(false);
          })
          .catch((e) => {
            alert(e);
          });
        } catch (error) {
          console.error('Error occurred during fetch:', error);
        }
      }
  return (
    <div className={styles.container}>
      {post.map((e) => (
        <>
        <div className={styles.descripiton_container}>
          <div className={styles.description}>
            {/* {window.localStorage.getItem('lang')} */}
            <h2>{window.localStorage.getItem('lang') === '"EN"' ? e.title
            : window.localStorage.getItem('lang') === '"KR"' ? e.titlekr : e.titlechn}</h2>
            <br />
            <p>{window.localStorage.getItem('lang') === '"EN"' ? e.description 
            : window.localStorage.getItem('lang') === '"KR"' ? e.descriptionkr : e.descriptionchn}</p>
          </div>
          <div className={styles.price_container}>
            <div className={styles.price_header}>
              {/* <p>Icon</p> */}
              <img src={icon} alt="" />
              <div className={styles.price_label}>
                <p>Vip Travel</p>
                <h2>${e.price}</h2>
              </div>
            </div>
            <div className={styles.plan_suggesion}>
            {( window.localStorage.getItem('lang') === '"EN"' ? (e.content ? e.content : ',') :
            window.localStorage.getItem('lang') === '"KR"' ? e.contentkr : e.contentchn).split(',').map((item, index) => (
                  <React.Fragment key={index}>
                    {item}
                    {index !== (window.localStorage.getItem('lang') === '"EN"' ? e.content :
                      window.localStorage.getItem('lang') === '"KR"' ? e.contentkr 
                      : e.contentchn).split(',').length - 1 && <br />}
                  </React.Fragment>
                ))}
            </div>
          </div>
        </div>
        <div className={styles.plan_container}>
          <img src={'https://wwa.zyberapi.site/' + e.cover} alt="" />
          {/* <p>plan</p> */}
          <Accordion
            className="accordion"
            allowMultipleExpanded={false}
            preExpanded={[0]}
          >
            <section className={styles.section}>Plans</section>
            {plan.map((item, i) => {
              return (
                <AccordionItem className={`accordionItem ${className}`} uuid={i} key={i}>
                  <AccordionItemHeading>
                    <AccordionItemButton className="flexCenter accordionButton ">
                        {/* just for getting state of item */}
                      <AccordionItemState>
                        {({ expanded }) =>
                          expanded
                            ? setClassName("expanded")
                            : setClassName("collapsed")
                        }
                      </AccordionItemState>
                      {/* <div className="flexCenter icon">{item.icon}</div> */}
                      <span
                        className="primaryText"
                      >
                        {window.localStorage.getItem('lang') === '"EN"' ? item.name : 
                        window.localStorage.getItem('lang') === '"KR"' ? item.krname : item.chnname}
                      </span>
                      <div className="flexCenter icon">
                        <MdOutlineArrowDropDown size={20} />
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="secondaryText">
                      {window.localStorage.getItem('lang') === '"EN"' ? item.description
                        : window.localStorage.getItem('lang') === '"KR"' ? item.krdescription : item.chndescription
                      }
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      </>
      ))}

      <div>
        <Link to={'/albumdetial/' + id} className={styles.album_title}>
          <p>See all albums</p>
          <FaArrowRight className={styles.right_arrow} />
        </Link>
        <div className={styles.album_container}>
          <br />
          <div className={styles.photo_album}>
            {photo.map((e) => (
              <div className={styles.album}>
                {/* <img src={'https://wwa.zyberapi.site/' + e.cover} alt="" /> */}
                {e.type === 'photo' ? <img className={styles.img} src={'https://wwa.zyberapi.site/' + e.cover} alt="" />
                : <video controls className={styles.img}>
                    <source hidden='none' src={"https://wwa.zyberapi.site/" + e.cover} 
                    type="video/mp4" />
                </video>}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.comment}>
        <p>comments</p>
        <br />
        <div className={styles.comment_container}>
          {comment.map((e) => (
            <div className={styles.comment_border}>
              <div className={styles.message_container}>
                  <img src={'https://wwa.zyberapi.site/' + e.cover} alt="" />
                  <div>
                    <h3>{e.name}</h3>
                    <h4>{e.country}</h4>
                  </div>
              </div>
              <div className={styles.userinfo}>
                  <p>{e.content}</p>                  
              </div>
            </div>
          ))}
        </div>
        <br />
        <div className={styles.inputs}>
          <div className={styles.info}>
            <div className={styles.profileAndName}>
              <br />
              <section>Profile picture</section>
              <input 
                type="file" 
                onChange={handleChange} 
                className={styles.custom_file_input}
                id='profile'
              />
              <label for="profile">
                { files === '' ? 
                  <img src={profile} className={styles.profile_photo} alt="" /> 
                  : <img src={file} className={styles.profile_photo} alt="" />}
              </label>
            </div>
            <div className={styles.nameinfo}>
              <label>
                <section>Name</section>
                <input 
                  type="text" 
                  placeholder='Name' 
                  value={name}
                  onChange={(e) => {setName(e.target.value)}}
                />
              </label>
              <br />
              <label>
                <section>Country</section>
                <input 
                  type="text" 
                  placeholder='Country' 
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </label>
            </div>
          </div>
          <div>
            <label>
              <section>Comment</section>
              <textarea 
                name="" 
                id="" 
                cols="30" 
                rows="10"
                className={styles.textarea}
                value={writeComment}
                onChange={(e) => setWriteComment(e.target.value)}
                placeholder='Comment'
              ></textarea>
            </label>
          </div>
          <div className={styles.button_container}>
            <button className={styles.commentButton} onClick={createNewPost}>Send</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlanDetail
