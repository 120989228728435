import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Navbar from './Components/Navbar/Navbar';
import Album from './Pages/Album/Album';
import Plans from './Pages/Plans/Plans';
import AlbumDetial from './Pages/Album/Detial/AlbumDetial';
import PlanDetail from './Pages/Plans/Detial/PlanDetail';
import { LangProvider } from './LangContext';
import Contact from './Pages/Contact/Contact';
import ContactFooter from './Components/ContactFooter/ContactFooter';

function App() {
  return (
    <LangProvider>
      <Router>
          <Navbar />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/album' element={<Album />} />
            <Route path='/albumdetial/:id' element={<AlbumDetial />} />
            <Route path='/plan' element={<Plans />} />
            <Route path='/plandetial/:id' element={<PlanDetail />} />
            <Route path='/contact' element={<Contact />} />
          </Routes>
          <ContactFooter />
      </Router>
    </LangProvider>
  );
}

export default App;
