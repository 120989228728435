import React, { useEffect } from 'react';
// import Snowflake from './Snowflake';
import styles from './Home.module.css';
import { Link } from 'react-router-dom';
// import axios from 'axios';
// import { LangContext } from '../../LangContext';
import { FaLocationDot } from "react-icons/fa6";
import mgl from '../../Assets/mgl.svg'

const Home = () => {
    // const {lang} = useContext(LangContext);
    // const [lang, setLang] = useState('');

    const lang = window.localStorage.getItem('lang');

    useEffect(() => {
      if(lang === null) {
        window.location.reload(false);
        // alert('reload');
      }
    }, [lang])

    // function snow() {
    //   let animationDelay = '0s';
    //   let fontSize = '100px';
    //   let arr = Array.from('Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!! Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!! Snowflakes are awesome!!! They are like little pieces of magic!!! Love snowflakes!!!');
      
    //   return arr.map((el, i) => {
    //     animationDelay = `${(Math.random()*16).toFixed(2)}s`;
    //     fontSize = `${(Math.floor(Math.random()*10) + 10)}px`;
    //     let style = {
    //       animationDelay,
    //       fontSize
    //     };
    //     return (<Snowflake key={i} id={i} style={style}/>);
    //   });
    // }

  return (
    <>
    <div className={styles.abs}>
        <div className={styles.mark_container}>
          <FaLocationDot size={15} color='#d3d3d3' style={{zIndex: 5, opacity: 0.3}} />
          <p className={styles.mark}>Delgerkhaan (3188m)</p>
        </div>
    </div>
    {lang === null ? <div className='loader_container'><span className='loader'></span></div> :
      <div className={styles.container}>
        <div className={styles.label}>
              <h1 className={styles.logo}>Winter Wild Adventure In Mongolia</h1>
              {/* {lang} */}
              {lang === '"EN"' ? 
                <div className={styles.desc}>
                  <p>
                    "Discover the extraordinary! 🇲🇳❄️ With Winter Wild Adventure LLC, you'll encounter
                  </p> <br />
                  <p>
                    the unparalleled wonders of Mongolia's extreme winter like nowhere else on Earth.
                  </p> <br />
                  <p>🚙🐴 Join us for an adventure you won't find anywhere else in the world!</p>
                </div> 
                : lang === '"CH"' ?
                <div className={styles.desc}>
                  <p>
                    “发现非凡！🇲🇳❄️ 通过 Winter Wild Adventure LLC，您会遇到
                  </p> <br />
                  <p>
                    蒙古极端冬季的无与伦比的奇观是地球上任何其他地方都没有的。
                  </p> <br />
                  <p>
                    🚙🐴 加入我们，进行世界上其他任何地方都找不到的冒险！
                  </p>
                </div>
                : 
                <div className={styles.desc}>
                  <p>
                    "특별함을 발견하세요! 🇲🇳❄️ Winter Wild Adventure LLC와 함께라면
                  </p> <br />
                  <p>
                    지구상 어느 곳에서도 볼 수 없는 몽골의 극한 겨울의 비교할 수 없는 경이로움.
                  </p> <br />
                  <p>
                    🚙🐴 세계 어디에서도 볼 수 없는 모험에 동참하세요!
                  </p>
                </div>
              }
              <div className={styles.button_container}> 
                <Link to={'/plan'} className={styles.button_second}>Plan</Link>
                <Link to={'/album'} className={styles.button}>Album</Link>
              </div>
              {/* <button className={styles.button_underlined}>About &gt;</button> */}
            </div>
          </div>}
        </>
  );
};


export default Home;