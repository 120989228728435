import React, { useEffect, useState } from 'react';
import styles from './AlbumDetial.module.css';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import bg from '../../../public/3.jpg';

const AlbumDetial = () => {
  const { id } = useParams();
  const [photos, setPhotos] = useState([]);
  const [category, setCategory] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMedia, setSelectedMedia] = useState(null); 

  useEffect(() => {
    if (isLoading) {
      axios.get(`https://wwa.zyberapi.site/categories/${id}/photo`)
        .then((response) => {
          setPhotos(response.data.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error);
          setIsLoading(false);
        });
      axios.get(`https://wwa.zyberapi.site/categories/${id}`)
        .then((response) => {
          setCategory(response.data.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error);
          setIsLoading(false);
        });
    }
  }, [id, isLoading]);

  const openModal = (media) => {
    setSelectedMedia(media);
  };

  const closeModal = () => {
    setSelectedMedia(null);
  };

  return (
    <div>
      <div>
        <article className={styles.article}>
          <img className={styles.picture} src={bg} alt="" />
          <div className={styles.header_container}>
            <h1 className={styles.header}>{category.name}</h1>
            <Link to={'/plandetial/' + category.id} className={styles.button}>
              See Plan <span className={styles.arrow}>&rarr;</span>
            </Link>
          </div>
        </article>
      </div>
      <div className={styles.container}>
        {!isLoading ? (
          photos.map((media) => (
            <div key={media.id}>
              {media.type === 'photo' ? (
                <img
                  className={styles.img}
                  onClick={() => openModal(media)}
                  src={`https://wwa.zyberapi.site/${media.cover}`}
                  alt=""
                />
              ) : (
                <video controls className={styles.img}>
                  <source
                    src={`https://wwa.zyberapi.site/${media.cover}`}
                    type="video/mp4"
                  />
                </video>
              )}
            </div>
          ))
        ) : (
          <div className={styles.container_skeleton}>
            {[...Array(5)].map((_, index) => (
              <div key={index} className='album_skeleton'></div>
            ))}
          </div>
        )}
      </div>

      {selectedMedia && (
        <div className={styles.modal}>
          <div onClick={closeModal} className={styles.overlay}></div>
          <div className={styles.modal_content}>
            {selectedMedia.type === 'photo' ? (
              <img
                className={styles.img_pop}
                src={`https://wwa.zyberapi.site/${selectedMedia.cover}`}
                alt=""
              />
            ) : (
              <video controls className={styles.img_pop}>
                <source
                  src={`https://wwa.zyberapi.site/${selectedMedia.cover}`}
                  type="video/mp4"
                />
              </video>
            )}
            <button className={styles.close_modal} onClick={closeModal}>
              X
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AlbumDetial;
