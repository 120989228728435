// Your React component
import React, { useEffect, useState } from 'react';
import './Navbar.css'; // Import your CSS file
// import axios from 'axios';
// import { LangContext } from '../../LangContext';
import { Link } from 'react-router-dom';
// import {ReactComponent as Logo} from '../../public/wwa_logo.svg';
import logo_2 from '../../public/white_logo.png';
import chn from '../../Assets/chn.png';
import kr from '../../Assets/kr.png';
import us from '../../Assets/us.png';
// import axios from 'axios';
import useGeoLocation from 'react-ipgeolocation';
import { IoChevronDown } from "react-icons/io5";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLang, setIsLang] = useState(false);
  // const [ipAddress, setIpAddress] = useState("");
  const location = useGeoLocation();
  // console.log(location.country);
  // alert(location.country)
  const lang = window.localStorage.getItem('lang')

  useEffect(() => {
    if(lang === null) {
      // alert(lang);
      window.localStorage.setItem('lang', location.country === 'CH' ? '"CH"' : location.country === 'KR' ? '"KR"' : '"EN"' );
    }
  }, [lang]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  // const saveLang = async (e) => {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       window.localStorage.setItem('lang', e);
  //       // alert(e);
  //       resolve();
  //     }, 3000);
  //   });
  // }
  
  // const handleChange = async (event) => {
  //   setIsLoading(true);
  //   saveLang(event.target.value)
  //     .then(() => {
  //       // Reload the page after a delay (e.g., 3 seconds)
  //       setTimeout(() => {
  //       setIsLoading(true);
  //         window.location.reload(false);
  //       }, 1000);
  //     })
  //     .catch((e) => console.log(e));
  // };
  

  return (
    <> {isLoading ? <div className='loader_container'><span className='loader'></span></div> :
    <nav className={`navbar ${menuOpen ? 'menu-open' : ''}`}>
      <div className="desktop">
        <div className="logo">
          <Link to={'/'} className="logo"><img className='logo-img' src={logo_2} alt="" /></Link>
        </div>
        <div className="menu">
          {/* {
            location.country
          } */}
          <Link to={'/'} className='item'>Home</Link>
          <Link to={'/album'} className='item'>Album</Link>
          <Link to={'/plan'} className='item'>Plan</Link>
          <div className='contact-div'>
            <Link to={'/contact'} onClick={() => setMenuOpen(false)} className='to-contact'>Contact</Link>
          </div>
          {/* <select 
            name="" id="" 
            onClick={handleChange} 
            // onChange={(e) => setLang(e.target.value)}
            className='select'
          >
            <option value={lang === '"EN"' ? '"EN"' : lang === '"KR"' ? '"KR"' : '"CH"'}>
              {lang === '"EN"' ? 'EN 🇺🇸' : lang === '"KR"' ? 'KR 🇰🇷' : 'CH 🇨🇳'}
            </option>
            <option value={lang === '"EN"' ? '"CH"' : lang === '"CH"' ? 'EN' : '"EN"'}>
              {lang === '"EN"' ? 'CH 🇨🇳' : lang === '"CH"' ? 'EN 🇺🇸' : 'EN 🇺🇸'}
            </option>
            <option value={lang === '"EN"' ? '"KR"' : lang === '"CH"' ? '"KR"' : '"CH"'}>
              {lang === '"EN"' ? 'KR 🇰🇷' : lang === '"CH"' ? 'KR 🇰🇷' : 'CH 🇨🇳'}
            </option>
          </select> */}
          <div onClick={() => setIsLang(!isLang)} className='select_div'>
              <div className='selected_div'>
                <p>{lang === '"EN"' ? 'En' : lang === '"KR"' ? 'Kr' : 'Ch'}</p>
                <img className='option_img' src={lang === '"EN"' ? us : lang === '"KR"' ? kr : chn} alt="" />
                <IoChevronDown />
              </div>
          </div>
          {isLang ? <div className='select_det'>
            <div onClick={() => {
                window.localStorage.setItem('lang', '"EN"');
                window.location.reload(false);
            }} className='option_div'>
              <p>En</p>
              <img className='option_img' src={us} alt="" />
            </div>
            <div onClick={() => {
                window.localStorage.setItem('lang', '"CH"');
                window.location.reload(false);
            }} className='option_div'>
              <p>Ch</p>
              <img className='option_img' src={chn} alt="" />
            </div>
            <div onClick={() => {
                window.localStorage.setItem('lang', '"KR"');
                window.location.reload(false);
            }} className='option_div'>
              <p>Kr</p>
              <img className='option_img' src={kr} alt="" />
            </div>
          </div> : <></>}
        </div>
      </div>

      <div className="mobile">
        {/* <Link to={'/'} className="logo"><Logo className='logo-img' src={logo} alt="" /></Link> */}
        <Link to={'/'} className="logo"><img className='logo-img' src={logo_2} alt="" /></Link>
        <div className='mobile-lang-menu'>
          {/* <select 
            name="" id="" 
            onChange={handleChange}
            className='select'
          >
            <option value={lang === '"EN"' ? '"EN"' : lang === '"KR"' ? '"KR"' : '"CH"'}>
              {lang === '"EN"' ? 'EN 🇺🇸' : lang === '"KR"' ? 'KR 🇰🇷' : 'CH 🇨🇳'}
            </option>
            <option value={lang === '"EN"' ? '"CH"' : lang === '"CH"' ? 'EN' : '"EN"'}>
              {lang === '"EN"' ? 'CH 🇨🇳' : lang === '"CH"' ? 'EN 🇺🇸' : 'EN 🇺🇸'}
            </option>
            <option value={lang === '"EN"' ? '"KR"' : lang === '"CH"' ? '"KR"' : '"CH"'}>
              {lang === '"EN"' ? 'KR 🇰🇷' : lang === '"CH"' ? 'KR 🇰🇷' : 'CH 🇨🇳'}
            </option>
          </select> */}
          <div onClick={() => setIsLang(!isLang)} className='select_div'>
              <div className='selected_div'>
                <p>{lang === '"EN"' ? 'EN' : lang === '"KR"' ? 'KR' : 'CH'}</p>
                <img className='option_img' src={lang === '"EN"' ? us : lang === '"KR"' ? kr : chn} alt="" />
                <IoChevronDown />
              </div>
          </div>
          {isLang ? <div className='select_det'>
            <div onClick={() => {
                window.localStorage.setItem('lang', '"EN"');
                window.location.reload(false);
            }} className='option_div'>
              <p>EN</p>
              <img className='option_img' src={us} alt="" />
            </div>
            <div onClick={() => {
                window.localStorage.setItem('lang', '"CH"');
                window.location.reload(false);
            }} className='option_div'>
              <p>CH</p>
              <img className='option_img' src={chn} alt="" />
            </div>
            <div onClick={() => {
                window.localStorage.setItem('lang', '"KR"');
                window.location.reload(false);
            }} className='option_div'>
              <p>KR</p>
              <img className='option_img' src={kr} alt="" />
            </div>
          </div> : <></>}
          <div className="hamburger-menu" onClick={toggleMenu}>
            &#9776;
          </div>
        </div>
      </div>

      {menuOpen && (
        <div className="mobile-menu">
          <div className="menu-header">
            <button className="cancel-button" onClick={closeMenu}>
              &times;
            </button>
          </div>
          <div className='ul_style'>
            {/* {location.country} */}
            <Link to={'/'} onClick={() => setMenuOpen(false)} className='item'>Home</Link>
            <Link to={'/album'} onClick={() => setMenuOpen(false)} className='item'>Album</Link>
            <Link to={'/plan'} onClick={() => setMenuOpen(false)} className='item'>Plan</Link>
            <Link to={'/contact'} onClick={() => setMenuOpen(false)} className='to-contact'>Contact</Link>
          </div>
        </div>
      )}
    </nav> } </>
  );
};

export default Navbar;
