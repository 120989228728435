import React, { useEffect, useState } from 'react'
import styles from './Album.module.css';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';

const Album = () => {
    const { lang } = useParams();
    const [category, setCategory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(isLoading) {
            axios.get(`https://wwa.zyberapi.site/categories`)
            .then((response) => {
                setCategory(response.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                alert(error);
                setIsLoading(false);
            });
        }
      }, [isLoading]);

  return (
    <div className={styles.unit}>
        <h2 className={styles.logo}>Travel Albums</h2>
        <div className={styles.container}>
            {isLoading === false ? category.map((e) => (
                <Link to={'/albumdetial/'+e.id} className={styles.item} key={e.id}>
                    <img className={styles.img} src={'https://wwa.zyberapi.site/' + e.cover} alt="" />
                    <div className={styles.title_container}>
                        <h3 className={styles.title}>{e.name}</h3>
                        <Link to={'/albumdetial/'+e.id} className={styles.button}>{lang}Detail</Link>
                    </div>
                </Link>
            )) : <div className={styles.container_skeleton}>
                    <div className='album_skeleton'></div>
                    <div className='album_skeleton'></div>
                    <div className='album_skeleton'></div>
                    <div className='album_skeleton'></div>
                </div>}
            {/* <button onClick={refreshPage}>{lang}</button> */}
        </div>
    </div>
  )
}

export default Album;
