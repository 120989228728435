import React, { useEffect, useState } from 'react'
import styles from './plan.module.css';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Plans = () => {
    const [category, setCategory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(isLoading) { 
            axios.get(`https://wwa.zyberapi.site/categories`)
            .then((response) => {
                setCategory(response.data.data);
                setIsLoading(false)
            })
            .catch((error) => {
                alert(error);
                setIsLoading(false)
            });
        }
      }, [isLoading]);
  return (
    <>
        <div className={styles.container}>
            {/* style={{background: `url('https://www.responsibletravel.com/imagesClient/S_184745.jpg')`}} */}
            {isLoading === false ? category.map((e) => (
                <Link to={'/plandetial/'+e.id}>
                    <article  className={styles.article}>
                        <img className={styles.picture} src={'https://wwa.zyberapi.site/' + e.cover} alt="" />
                        <h1 className={styles.header}>{e.name}</h1>
                    </article>
                </Link>
            )) : <div className={styles.container}> 
                    <div className='plan_skeleton'></div> 
                    <div className='plan_skeleton'></div> 
                    <div className='plan_skeleton'></div> 
                    <div className='plan_skeleton'></div> 
                </div>}
        </div>
    </>
  )
}

export default Plans
