import React, { useState } from 'react'
import ig from '../../Assets/ig.png';
import fb from '../../Assets/fb.png';
import kakoa from '../../Assets/KakaoTalk_logo.svg';
import wechat from '../../Assets/wechat.png';
import styles from './Contactfooter.module.css';
import { FaSquarePhoneFlip } from "react-icons/fa6";
import { IoCloseCircleOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';

const ContactFooter = () => {
    const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.container}>
      {!isOpen ? <button className={styles.button} onClick={() => setIsOpen(true)}> <FaSquarePhoneFlip color='#14d102' size={
          window.screen.width > 660 ? 50 : 40
        } /> </button>:
        <div className={styles.icons}> 
            <Link to="https://instagram.com">
                <img src={ig} className={styles.icon} alt="" />
            </Link>
            <Link to="https://facebook.com">
                <img src={fb} className={styles.icon} alt="" />
            </Link>
            <Link to="https://www.kakaocorp.com/">
                <img src={kakoa} className={styles.icon} alt="" />
            </Link>
            <Link to="https://www.wechat.com/">
                <img src={wechat} className={styles.icon} alt="" />
            </Link>
            <button onClick={() => setIsOpen(false)} className={styles.button}>
                <IoCloseCircleOutline color='red' size={
                  window.screen.width > 660 ? 50 : 40
                } />
            </button>
        </div>
      }
    </div>
  )
}

export default ContactFooter
